import { 
	Alert,
	Box,
	Button,
	CircularProgress, 
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Paper,
	Snackbar,
	Typography,
 } from '@mui/material';
 import {
	Edit as EditIcon,
	Delete as DeleteIcon,
 } from '@mui/icons-material';
import { Upload as UploadIcon, Add as AddIcon } from '@mui/icons-material';
import { FileOpen, FileOpenOutlined } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridRenderCellParams, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useState, useRef } from 'react';
import { DeleteLine, fetcher, UploadCigarImport } from '../apiservice';
import { useNavigate, NavigateFunction, Link } from 'react-router-dom';
import { CigarLine } from './Models';
import useSWR from 'swr';

let navigate: NavigateFunction;


function Cigars() {
	navigate = useNavigate();
	const [deleteItem, setDeleteItem] = useState<{id: string, name: string} | null>(null);
	const [pendingRequest, setPendingRequest] = useState<boolean>(false);

	const columns = [
		{ field: 'thumbnail', renderCell: (params: GridRenderCellParams<string>) => params.value === null ? "-" : <img alt="" src={ `data:image/png;base64,${params.value}`} style={{width: "60%", height: "60%"}} />, width: 80, filterable: false},
		{ field: 'brandName', headerName: 'Brand', valueGetter: (params: any) => params.row.brand.name, width: 160 },
		{ field: 'name', headerName: 'Line' , width: 240 },
		{ field: 'actions', type: 'actions', getActions: (params: any) => [
			<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => navigate(`/cigars/${params.row.id}`)} />,
			<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setDeleteItem({id: params.row.id, name: params.row.name})} />,
		]}
	];

	const { data, error, isLoading, mutate } = useSWR('/v1/cigar', fetcher);

	if (isLoading) return <CircularProgress />;
	if (error) return <Typography paragraph>{ error.message }</Typography>;
	return (
		<div style={{ height: 800, width: '100%' }}>
			<DataGrid 
				rows={data!}	
				columns={columns}
				pageSize={25}
				getRowId={(row) => row.id!}
				components={{
					Toolbar: CigarsToolbar,
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: 'brandName', sort: 'asc' }],
					}
				}}
			/>
						<Dialog 
				open={deleteItem != null} 
				onClose={() => {
					setDeleteItem(null);
				}}>
				<DialogTitle>
					{`Delete brand "${deleteItem?.name}"?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This action can not be undone.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteItem(null)}>Cancel</Button>
					<Button onClick={async () => {
						setPendingRequest(true);
						await DeleteLine(deleteItem!.id);
						setDeleteItem(null);
						mutate({ data: data!.filter((e: CigarLine) => e.id !== deleteItem!.id) })
						setPendingRequest(false);
					}} disabled={pendingRequest}>Delete</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function CigarsToolbar() {
	const [dialogOpen, setOpen] = useState<boolean>(false);
	const [{importStatusOpen, importError}, setImportStatus] = useState<{importStatusOpen: boolean, importError: Error | null}>({importStatusOpen: false, importError: null});

	return (
		<div>
			<GridToolbarContainer>
				<GridToolbarFilterButton />
				<Button variant="text" startIcon={ <UploadIcon /> } onClick={ () => setOpen(true) }>Import</Button>
				<Link to="/cigars/new"><Button variant="text" startIcon={ <AddIcon /> }>New Line</Button></Link>
			</GridToolbarContainer>
			<ImportDialog 
				open={ dialogOpen } 
				closeCallback={ () => setOpen(false) }
				successCallback={ () => setImportStatus({importStatusOpen: true, importError: null})}
				errorCallback={ (err: Error) => setImportStatus({importStatusOpen: true, importError: err})}		
			/>
			<Snackbar open={importStatusOpen} autoHideDuration={1500}>
				<Alert severity={ importError ? "error" : "success" }>
					{ importError ? importError.message : "File imported successfully!" }
				</Alert>
			</Snackbar>
		</div>
	)
}

function ImportDialog({open, closeCallback, successCallback, errorCallback}:
	{open: boolean, closeCallback: () => void, successCallback: () => void, errorCallback: (err: Error) => void}) {
		const [filename, setFilename] = useState<string | null>(null);
		const importFile = useRef<HTMLInputElement>(null);

		const close = () => {
			closeCallback();
			setFilename(null);
		}

		const uploadFile = async () => {
			closeCallback();
			setFilename(null);

			try {
				await UploadCigarImport(importFile.current!.files![0]);
				successCallback();
			} catch (err: any) {
				errorCallback(err);
			}
		}

		return (
			<Dialog open={open} onClose={close}>
				<DialogTitle>Import File</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Alert severity="warning">
							There is very little error checking on this function! Please make sure
							that your file is correct and test it by first importing into a
							development environment.
						</Alert>
						Select a file to import. You can download the template <Link to={`${process.env.PUBLIC_URL}/import_template.xlsx`}>here</Link>.
					</DialogContentText>
				</DialogContent>
				<Box sx={{ margin: 8 }}> 
					<Paper 
						sx={{ 
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							padding: 2,
							cursor: 'pointer',
						}}
						onClick={() => importFile.current!.click()}
					>
						{ filename ? <FileOpen color="primary" sx={{fontSize: 100}} /> : <FileOpenOutlined color="primary" sx={{ fontSize: 100 }} /> }
						<Typography>{ filename ?? 'Drag here to import, or click to browse' }</Typography>
					</Paper>
				</Box>
				<DialogActions>
					<Button onClick={close}>Cancel</Button>
					<Button onClick={uploadFile} disabled={filename == null}>Upload</Button>
				</DialogActions>

				<input type='file' id='importFile' ref={importFile} accept='.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' style={{display: 'none'}} onChange={(prop) => setFilename(prop.target.value)} />
			</Dialog>
		)
}

export { Cigars };