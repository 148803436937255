import { 
	Button, 
	CircularProgress, 
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography 
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { DeleteBrand, fetcher } from "../apiservice";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Brand } from "./Models"
import useSWR from 'swr'
 
let navigate: NavigateFunction;

export function Brands() {
	navigate = useNavigate();
	const {data, error: err, isLoading, mutate} = useSWR('/v1/cigar/brand', fetcher)
	const [deleteItem, setDeleteItem] = useState<{id: string, name: string} | null>(null);
	const [pendingRequest, setPendingRequest] = useState<boolean>(false);
	const columns = [
		{ field: 'name', headerName: 'Brand' , width: 240 },
		{ field: 'actions', type: 'actions', getActions: (params: any) => [
			<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => navigate(`/brands/${params.row.id}`)} />,
			<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setDeleteItem({id: params.row.id, name: params.row.name})} />,
		]}
	];

	if (isLoading) return <CircularProgress />;
	if (err) return <Typography paragraph>{ err.message }</Typography>;
	return (
		<div style={{ height: 800, width: '100%' }}>
			<DataGrid 
				rows={data}	
				columns={columns}
				pageSize={25}
				getRowId={(row) => row.id}
				loading={isLoading}
				components={{
					 Toolbar: BrandsToolbar,
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: 'name', sort: 'asc' }],
					}
				}}
			/>
			<Dialog 
				open={deleteItem != null} 
				onClose={() => {
					setDeleteItem(null);
				}}>
				<DialogTitle>
					{`Delete brand "${deleteItem?.name}"?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This action can not be undone.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteItem(null)}>Cancel</Button>
					<Button onClick={async () => {
						setPendingRequest(true);
						await DeleteBrand(deleteItem!.id)
						mutate({data: data!.filter((brand: Brand) => brand.id !== deleteItem!.id)})
						setDeleteItem(null);
						setPendingRequest(false);
					}} disabled={pendingRequest}>Delete</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function BrandsToolbar() {
	return (
		<div>
			<GridToolbarContainer>
				<GridToolbarFilterButton />
				<Link to="/brands/new"><Button variant="text" startIcon={ <AddIcon /> }>New Brand</Button></Link>
			</GridToolbarContainer>
		</div>
	)
}