import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ImageNotSupported } from "@mui/icons-material";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo, useRef } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import {
  DeleteTempLine,
  GetCigarLineImage,
  MergeLineIntoLine,
  SearchCigarLines,
} from "../apiservice";
import { CigarLine, TempLine } from "./Models";
import { mutate } from "swr";

function useDebounce(value: any, delay: number) {
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setTempValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return tempValue;
}

export function ReviewDetail() {
  const temp = useLoaderData() as CigarLine;

  const tempLine = useMemo(() => {
    const brand = temp.brand!.name ?? "";
    return {
      id: temp.id,
      name: temp.name,
      brand_name: brand,
      thumbnail: temp.thumbnail,
    } as TempLine;
  }, [temp.id, temp.name, temp.thumbnail, temp.brand]);
  const [imageDetailOpen, setImageDetailOpen] = useState<boolean>(false);

  const [cigarList, setCigarList] = useState<CigarLine[]>([]);
  const [lineSearchTerm, setLineSearchTerm] = useState<string>("");
  const [linesLoading, setLinesLoading] = useState<boolean>(false);
  const debouncedLineSearch = useDebounce(lineSearchTerm, 500);

  const [selectedLine, setSelectedLine] = useState<CigarLine | null>(null);
  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(
    null,
  );
  const [selectedLineImageOpen, setSelectedLineImageOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();

  // Set up Google Gemini API
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY!);
  const model = genAI.getGenerativeModel({ model: 'gemini-pro' });

  useEffect(() => {
    async function FetchLines() {
      setLinesLoading(true);
      setCigarList([]);

      const data = await SearchCigarLines(debouncedLineSearch);
      setCigarList(data);
      setLinesLoading(false);
    }

    if (debouncedLineSearch && debouncedLineSearch.length > 1) {
      FetchLines();
    } else {
      setCigarList([]);
      setSelectedLine(null);
    }
  }, [debouncedLineSearch]);

  const getLineNamesCalled = useRef(false);

  useEffect(() => {
    if (!getLineNamesCalled.current) {
      getLineNamesCalled.current = true; // Mark as called
      async function getLineNames() {
        const prompt = `Determine what the correct brand name and cigar line name should be for the following user entered cigar: ${tempLine.brand_name} ${tempLine.name}. Return only a string containing the brand name and line name.`;
        const result = await model.generateContent(prompt);
        const response = await result.response;
        return response.text();
      }

      getLineNames().then((lineNames) => {
        console.log(`Updating line names: ${lineNames}`);
        setLineSearchTerm(lineNames);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Still using an empty dependency array

  function SelectedLineDetail({ selectedLine }: { selectedLine: CigarLine }) {
    if (!selectedLine) {
      return null;
    }
    return (
      <Grid container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {selectedLine.thumbnail ? (
            <Grid
              item
              xs={6}
              justifyContent="center"
              alignContent="center"
              style={{
                backgroundImage: `url(data:image/png;base64,${selectedLine.thumbnail})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: 200,
                width: 200,
                cursor: "pointer",
              }}
              onClick={() => setSelectedLineImageOpen(true)}
            />
          ) : (
            <ImageNotSupported sx={{ height: 200, width: 200 }} />
          )}
          {selectedLineImageOpen ? (
            <ImageDetail
              cigarId={selectedLine.id!}
              closeCallback={() => setSelectedLineImageOpen(false)}
            />
          ) : null}
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs="auto">
            <Typography>
              <Box component="span" fontWeight={700}>
                Line:{" "}
              </Box>
              {selectedLine?.name}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography>
              <Box component="span" fontWeight={700}>
                Brand:{" "}
              </Box>
              {selectedLine?.brand?.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs="auto"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography align="left">
              <Box component="span" fontWeight={700}>
                Description:{" "}
              </Box>
              {selectedLine?.description}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography>
              <Box component="span" fontWeight={700}>
                Filler:{" "}
              </Box>
              {selectedLine?.filler_origin}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography>
              <Box component="span" fontWeight={700}>
                Wrapper:{" "}
              </Box>
              {selectedLine?.wrapper_origin}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography>
              <Box component="span" fontWeight={700}>
                Binder:{" "}
              </Box>
              {selectedLine?.binder_origin}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography>
              <Box component="span" fontWeight={700}>
                Vitolas:{" "}
              </Box>
              {selectedLine?.vitolas?.map((vitola) => vitola.name).join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item>
          <Box m={1} padding={1}>
            <Paper sx={{ height: 220, width: 200, p: 1, objectFit: "contain" }}>
              {tempLine.thumbnail ? (
                <Grid
                  item
                  xs={12}
                  justifyContent={"center"}
                  style={{
                    backgroundImage: `url(data:image/png;base64,${tempLine.thumbnail})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: 200,
                    width: 200,
                    cursor: "pointer",
                  }}
                  onClick={() => setImageDetailOpen(true)}
                />
              ) : (
                <ImageNotSupported sx={{ height: 180, width: 180 }} />
              )}
              {imageDetailOpen ? (
                <ImageDetail
                  cigarId={tempLine.id}
                  closeCallback={() => setImageDetailOpen(false)}
                />
              ) : null}
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box m={1} padding={1}>
            <Paper>
              <Box m={1} padding={1}>
                <Typography variant="h4" sx={{ textAlign: "left" }}>
                  Brand: {tempLine.brand_name}
                </Typography>
                <Typography variant="h4" sx={{ textAlign: "left" }}>
                  Line: {tempLine.name}
                </Typography>
                <Box padding={3}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          mutate("/v1/cigar/temporary", () => DeleteTempLine(tempLine.id));
                          navigate("/review_lines");
                        }}
                      >
                        Delete Line
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          return navigate(
                            `/cigars/new?line_name=${tempLine.name}&line_id=${tempLine.id}&brand_name=${tempLine.brand_name}`,
                          );
                        }}
                      >
                        Create New Line
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          mutate("/v1/cigar/temporary", () => MergeLineIntoLine(tempLine.id, selectedLine!.id!))
                          navigate("/review_lines")
                        }}
                      >
                        Merge to Selected
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={6}>
          <Paper>
            <Box m={1} padding={2}>
              <Typography variant="h4" sx={{ textAlign: "left" }}>
                Compare to an existing line:
              </Typography>

              <Grid
                container
                direction="row"
                padding={3}
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="search"
                  label="Search"
                  fullWidth={true}
                  placeholder="Search lines.."
                  onChange={(e) => {
                    setLineSearchTerm(e.target.value);
                  }}
                  value={lineSearchTerm}
                />
                {linesLoading && <p>Loading..</p> && <CircularProgress />}
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                padding={2}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <List
                  style={{
                    maxHeight: 400,
                    overflow: "auto",
                    border: "1px solid red",
                  }}
                >
                  {cigarList.map((cigar, index) => (
                    <ListItemButton
                      key={cigar.id}
                      selected={selectedLineIndex === index}
                      onClick={() => {
                        setSelectedLine(cigar);
                        setSelectedLineIndex(index);
                      }}
                    >
                      {cigar.thumbnail ? (
                        <img
                          height={30}
                          width={30}
                          src={`data:image/png;base64,${cigar.thumbnail}`}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ImageNotSupported sx={{ height: 30, width: 30 }} />
                      )}
                      <ListItemText
                        primary={cigar.name}
                        secondary={cigar.brand?.name}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Box m={1} padding={1}>
              <Typography variant="h4" sx={{ textAlign: "left" }}>
                Selected Line details:
              </Typography>
              {selectedLine && SelectedLineDetail({ selectedLine })}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

function ImageDetail({
  cigarId,
  closeCallback,
}: {
  cigarId: string;
  closeCallback: () => void;
}) {
  const [cigarImage, setCigarImage] = useState<{ image: string } | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      const image = await GetCigarLineImage(cigarId);
      setCigarImage(image);
      setOpen(true);
    })();
  }, [cigarId]);
  return (
    <Dialog open={open} onClose={closeCallback} maxWidth={"lg"}>
      <DialogContent>
        {cigarImage == null ? (
          <CircularProgress />
        ) : (
          <img src={`data:image/png;base64,${cigarImage.image}`} alt="" />
        )}
      </DialogContent>
    </Dialog>
  );
}
