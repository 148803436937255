import { 
	Button, 
	CircularProgress, 
	Dialog, 
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle, 
	Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { DeleteManufacturer, GetManufacturers } from "../apiservice";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Manufacturer } from "./Models";
 
let navigate: NavigateFunction;

function Manufacturers() {
	navigate = useNavigate();
	const [{data, err, isLoading}, setManufacturers] = useState<{data: Manufacturer[] | null, err: Error | null, isLoading: boolean}>({data: null, err: null, isLoading: true});
	const [deleteItem, setDeleteItem] = useState<{id: string, name: string} | null>(null);
	const [pendingRequest, setPendingRequest] = useState<boolean>(false);
	const columns = [
		{ field: 'name', headerName: 'Manufacturer' , width: 240 },
		{ field: 'actions', type: 'actions', getActions: (params: any) => [
			<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => navigate(`/manufacturers/${params.row.id}`)} />,
			<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setDeleteItem({id: params.row.id, name: params.row.name})} />,
		]}
	];

	useEffect(() => {
		async function fetchData() {
			try {
				const results = await GetManufacturers();
				setManufacturers({data: results, err: null, isLoading: false});
			} catch (e: any) {
				setManufacturers({data: null, err: e, isLoading: false});
			}
		}
		fetchData();
	}, []);

	if (isLoading) return <CircularProgress />;
	if (err) return <Typography paragraph>{ err.message }</Typography>;
	return (
		<div style={{ height: 800, width: '100%' }}>
			<DataGrid 
				rows={data!}	
				columns={columns}
				pageSize={25}
				getRowId={(row) => row.id}
				components={{
					 Toolbar: ManufacturersToolbar,
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: 'name', sort: 'asc' }],
					}
				}}
			/>
			<Dialog 
				open={deleteItem != null} 
				onClose={() => {
					setDeleteItem(null);
				}}>
				<DialogTitle>
					{`Delete manufacturer "${deleteItem?.name}"?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This action can not be undone.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteItem(null)}>Cancel</Button>
					<Button onClick={async () => {
						setPendingRequest(true);
						await DeleteManufacturer(deleteItem!.id);
						setDeleteItem(null);
						setManufacturers((oldState) => {
							return {
								data: oldState.data!.filter((e) => e.id !== deleteItem!.id),
								err: null,
								isLoading: false
							}
						})
						setPendingRequest(false);
					}} disabled={pendingRequest}>Delete</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function ManufacturersToolbar() {
	return (
		<div>
			<GridToolbarContainer>
				<GridToolbarFilterButton />
				<Link to="/manufacturers/new"><Button variant="text" startIcon={ <AddIcon /> }>New Manufacturer</Button></Link>
			</GridToolbarContainer>
		</div>
	)
}

export { Manufacturers };