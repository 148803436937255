import { Button, Drawer, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { CreateManufacturer, UpdateManufacturer } from "../apiservice";
import { Manufacturer } from "./Models";

export function ManufacturerDetail() {
	const defaultManufacturer = {
		name: '',
	};
	let manufacturer = useLoaderData() as Manufacturer;
	const [{currentState, isDirty}, setEditState] = useState<{currentState: Manufacturer, isDirty: boolean}>({currentState: manufacturer ?? defaultManufacturer, isDirty: false});
	const [editFields, setEditFields] = useState<{name?: boolean}>({});
	const nameEdit = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		setEditFields({
			name: !manufacturer?.name,
		});
	}, [manufacturer]);

	return (
		<Container>
			{ editFields.name ?
				<TextField 
					id="name-edit" 
					ref={nameEdit}
					label="Name" 
					variant="outlined" 
					inputProps={{style: {fontSize: "5.5rem"}}} // font size of input text
					value={currentState.name} 
					onKeyDown={(e) => {
						if (e.key === "Enter") setEditFields({...editFields, name: false});
						if (e.key === "Escape") {
							setEditState({currentState: {...currentState, name: manufacturer.name}, isDirty: true});
							setEditFields({...editFields, name: false});
						}
					}}
					onChange={(e) => {
						setEditState({currentState: {...currentState, name:e.target.value}, isDirty: true});
					}}
				/> :
				<Typography variant="h1" sx={{cursor: 'pointer'}} onClick={() => setEditFields({...editFields, name: true})}>{ currentState.name }</Typography>
			}
			<Drawer open={isDirty} anchor={'bottom'} variant={'persistent'} >
				<Button onClick={() => setEditState({currentState: manufacturer ?? defaultManufacturer, isDirty: false})} >
					Cancel
				</Button>
				<Button onClick={async () => {
					if (!currentState.id) {
						const newMfg = await CreateManufacturer(currentState) as Manufacturer;
						// Clear edit state
						setEditState({currentState: newMfg, isDirty: false});
						setEditFields({});
						return navigate(`/manufacturers/${newMfg.id}`);
					}
					const updated = await UpdateManufacturer(currentState);
					manufacturer = updated
					setEditState({currentState: updated, isDirty: false})
				}}>Save</Button>
			</Drawer>
		</Container>
	)
}