import { Brand, CigarLine, Manufacturer } from "./cigars/Models";

var etags: {[key: string]: string} = {};

export const fetcher = (...args: [input: RequestInfo, init?: RequestInit | undefined]) => fetch(`${process.env.REACT_APP_API_URL}${args[0]}`, { ...args[1], credentials: 'include' }).then(res => res.json());

export async function getAPIPath(path: string) {
	const base_url = process.env.REACT_APP_API_URL;
	let headers = {};
	if (etags[path]) {
		headers = {
			'If-None-Match': etags[path],
		}
	}

	let res = await fetch(`${base_url}${path}`, {
		credentials: 'include',
		headers: headers,
	});

	if (!res.ok) {
		throw new Error(await res.text());
	}

	if (res.headers.has('etag')) {
		etags[path] = res.headers.get('etag')!;
	}

	return res.json();
}

export function SearchCigarLines(query: string | null) {
	return getAPIPath(`/v1/cigar?search_string=${query}`);
}

export async function GetBrands(): Promise< Brand[] > {
	return getAPIPath(`/v1/cigar/brand`);
}

export async function GetManufacturers(): Promise< Manufacturer[] > {
	return getAPIPath(`/v1/cigar/manufacturer`);
}

export function GetCigarDetail(id: string): Promise < CigarLine > {
	return getAPIPath(`/v1/cigar/${id}`);
}

export function GetBrandDetail(id: string): Promise < Brand > {
	return getAPIPath(`/v1/cigar/brand/${id}`);
}

export function GetManufacturerDetail(id: string): Promise < Manufacturer > {
	return getAPIPath(`/v1/cigar/manufacturer/${id}`);
}

export async function MergeLineIntoLine(movingID: string, stayingID: string): Promise<any> {
	const base_url = process.env.REACT_APP_API_URL;
	let path = `/admin/merge/${movingID}/${stayingID}`;
	let headers = {};
	if (etags[path]) {
		headers = {
			'If-None-Match': etags[path],
		}
	}

	let res = await fetch(`${base_url}${path}`, {
		credentials: 'include',
		headers: headers,
	});

	if (!res.ok) {
		throw new Error(await res.text());
	}
	return;
}

export async function UploadCigarImport(file: File): Promise <void> {
	const fd = new FormData();
	fd.append('import', file);
	const base_url = process.env.REACT_APP_API_URL;
	const path = "/v1/cigar";
	const res = await fetch(`${base_url}${path}`, {
		method: "POST",
		body: fd,
		credentials: 'include',
	});

	if (!res.ok) {
		throw new Error(`Error importing data: ${await res.text()}`);
	}
}

export async function UploadCigarLineImage(lineID: string, file: File): Promise<any> {
	const fd = new FormData();
	fd.append('image', file);
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/${lineID}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "PUT",
		body: fd,
		credentials: 'include',
	});

	if (!res.ok) {
		throw new Error(`Error uploading image: ${await res.text()}`);
	}

	return res.json();
}

export async function CreateLineFromTempLine(templine: {id?: string, vitolas: Array<{id?: string}>}): Promise<{id: string}> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/admin/templine/${templine.id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "POST",
		body: JSON.stringify(templine),
		credentials: 'include',
		headers: {
			'Content-type': 'application/json',
		}
	});

	if (!res.ok) {
		throw new Error(`Error creating cigar line: ${await res.text()}`);
	}

	return res.json();
}

export async function CreateCigarLine(line: {vitolas: Array<{id?: string}>}): Promise<{id: string}> {
	line.vitolas.forEach((v) => { delete v.id; });
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar`;
	const res = await fetch(`${base_url}${path}`, {
		method: "POST",
		body: JSON.stringify(line),
		credentials: 'include',
		headers: {
			'Content-type': 'application/json',
		}
	});

	if (!res.ok) {
		throw new Error(`Error creating cigar line: ${await res.text()}`);
	}

	return res.json();
}



export async function UpdateCigarLine(line: {id?: string, vitolas: Array<{id?: string, isNew?: boolean}>}): Promise<any> {
	line.vitolas.forEach((v) => {if (v.isNew) delete v.id;})
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/${line.id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "PUT",
		body: JSON.stringify(line),
		credentials: 'include',
		headers: {
			'Content-type': 'application/json',
		}
	});

	if (!res.ok) {
		throw new Error(`Error updating cigar line: ${await res.text()}`);
	}

	return res.json();
}

export async function GetCigarLineImage(lineID: string): Promise< {image: string} > {
	return getAPIPath(`/v1/cigar/${lineID}/image`);
}

export async function CreateBrand(brand: {}): Promise<{id: string}> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/brand`;
	const res = await fetch(`${base_url}${path}`, {
		method: "POST",
		body: JSON.stringify(brand),
		credentials: 'include',
		headers: {
			'Content-type': 'application/json',
		}
	});

	if (!res.ok) {
		throw new Error(`Error creating brand: ${await res.text()}`);
	}

	return res.json();
}

export async function UpdateBrand(brand: {id: string,} ): Promise<any> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/brand/${brand.id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "PUT",
		body: JSON.stringify(brand),
		credentials: 'include',
		headers: {
			'Content-type': 'application/json',
		}
	});

	if (!res.ok) {
		throw new Error(`Error updating cigar line: ${await res.text()}`);
	}

	return res.json();
}

export async function CreateManufacturer(mfg: {}): Promise<{id: string}> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/manufacturer`;
	const res = await fetch(`${base_url}${path}`, {
		method: "POST",
		body: JSON.stringify(mfg),
		credentials: 'include',
		headers: {
			'Content-type': 'application/json',
		}
	});

	if (!res.ok) {
		throw new Error(`Error creating manufacturer: ${await res.text()}`);
	}

	return res.json();
}

export async function UpdateManufacturer(mfg: {id: string,} ): Promise<any> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/manufacturer/${mfg.id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "PUT",
		body: JSON.stringify(mfg),
		credentials: 'include',
		headers: {
			'Content-type': 'application/json',
		}
	});

	if (!res.ok) {
		throw new Error(`Error updating cigar line: ${await res.text()}`);
	}

	return res.json();
}

export async function DeleteManufacturer(id: string): Promise<any> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/manufacturer/${id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "DELETE",
		credentials: 'include',
	});

	if (!res.ok) {
		throw new Error(`Error deleting manufacturer: ${await res.text()}`);
	}
}

export async function DeleteBrand(id: string): Promise<any> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/brand/${id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "DELETE",
		credentials: 'include',
	});

	if (!res.ok) {
		throw new Error(`Error deleting brand: ${await res.text()}`);
	}
}

export async function DeleteLine(id: string): Promise<any> {
	const base_url = process.env.REACT_APP_API_URL;
	const path = `/v1/cigar/${id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "DELETE",
		credentials: 'include',
	});

	if (!res.ok) {
		throw new Error(`Error deleting line: ${await res.text()}`);
	}
}

export async function DeleteTempLine(id: string): Promise<any> {
	const base_url = process.env.REACT_APP_API_URL;

	const checkin_path = `/v1/checkin/temp/${id}`;
	const checkin_res = await fetch(`${base_url}${checkin_path}`, {
		method: "DELETE",
		credentials: 'include',
	});

	if (!checkin_res.ok) {
		throw new Error(`Error deleting temp line checkin: ${await checkin_res.text()}`);
	}

	const path = `/v1/cigar/temporary/${id}`;
	const res = await fetch(`${base_url}${path}`, {
		method: "DELETE",
		credentials: 'include',
	});

	if (!res.ok) {
		throw new Error(`Error deleting temp line: ${await res.text()}`);
	}
}