import {
	Box, 
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Paper,
	Typography,
} from '@mui/material';
import {
	FileOpen,
	FileOpenOutlined,
} from '@mui/icons-material';
import { useRef, useState } from 'react';
import { UploadCigarLineImage } from '../apiservice';
import './UploadImageDialog.css';


export function UploadImageDialog({cigarId, open, closeCallback, successCallback, errorCallback}:
	{cigarId: string, open: boolean, closeCallback: () => void, successCallback: (data: any) => void, errorCallback: (err: Error) => void}) {
		const [filename, setFilename] = useState<string | null>(null);
		const importFile = useRef<HTMLInputElement>(null);

		const close = () => {
			closeCallback();
			setFilename(null);
		}

		const uploadFile = async () => {
			closeCallback();
			setFilename(null);

			try {
				const data = await UploadCigarLineImage(cigarId, importFile.current!.files![0]);
				successCallback(data);
			} catch (err: any) {
				errorCallback(err);
			}
		}

		return (
			<Dialog open={open} onClose={close}>
				<DialogTitle>Upload Cigar Line Image</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Select an image to upload. The image will be scaled and cropped by the server. 
					</DialogContentText>
				</DialogContent>
				<Box sx={{ margin: 8 }}> 
					<Paper 
						sx={{ 
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							padding: 2,
							cursor: 'pointer',
						}}
						onClick={() => importFile.current!.click()}
						onDragOver={(ev) => {
							ev.preventDefault();
							ev.currentTarget.className = "droptarget-hover";
						}}
						onDrop={(ev) => {
							ev.preventDefault();
							importFile.current!.files = ev.dataTransfer.files;
							setFilename(ev.dataTransfer.files[0].name);
							ev.currentTarget.className = "";
						}}
						onDragEnd={(ev) => {
							ev.currentTarget.className = "";
						}}
					>
						{ filename ? <FileOpen color="primary" sx={{fontSize: 100}} /> : <FileOpenOutlined color="primary" sx={{ fontSize: 100 }} /> }
						<Typography>{ filename ?? 'Drag here to import, or click to browse' }</Typography>
					</Paper>
				</Box>
				<DialogActions>
					<Button onClick={close}>Cancel</Button>
					<Button onClick={uploadFile} disabled={filename == null}>Upload</Button>
				</DialogActions>

				<input type='file' id='importFile' ref={importFile} accept='image/*' style={{display: 'none'}} onChange={(prop) => setFilename(prop.target.value)} />
			</Dialog>
		)
}