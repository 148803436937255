import { SetStateAction, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	FormControlLabel,
	FormGroup
} from '@mui/material';
import {
	Add as AddIcon,
	Cancel as CancelIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Save as SaveIcon,
	AutoAwesome,
} from '@mui/icons-material';
import { 
	DataGrid,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	GridToolbarContainer, 
	MuiEvent
} from '@mui/x-data-grid';
import { Vitola } from './Models';
import React from 'react';

interface EditToolbarProps {
	setRows: (value: SetStateAction<Vitola[]>) => void;
	setRowModesModel: (
		newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
	) => void;
	geminiVitolas: Vitola[] | undefined;
	setGeminiModalOpen: (value: SetStateAction<boolean>) => void;
}

function EditToolbar(props: EditToolbarProps) {
	const { setRows, setRowModesModel, geminiVitolas, setGeminiModalOpen } = props;

	const addButtonClick = () => {
		// Generate a random UUID
		const id = Math.random().toString(36).substring(7);
		setRows((oldRows) => [...oldRows, {id: id, name: '', shape: 'Parejo', isNew: true }]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
		}));
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={<AddIcon />} onClick={addButtonClick}>
				Add vitola
			</Button>
			{ geminiVitolas?.length !== 0 ?
				<Button color="primary" startIcon={<AutoAwesome />} onClick={() => setGeminiModalOpen(true)}>
					Add Gemini vitolas
				</Button>
				: null	
			}
		</GridToolbarContainer>
	);
}

export function VitolaGrid({vitolas, setVitolas, geminiVitolas}: {vitolas: Vitola[], setVitolas: (newValue: SetStateAction<Vitola[]>) => void, geminiVitolas: Vitola[] | undefined}) {
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const [geminiModalOpen, setGeminiModalOpen] = useState<boolean>(false);
	const [selectedGeminiVitolas, setSelectedGeminiVitolas] = useState<Vitola[]>([])

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>,
	  ) => {
		event.defaultMuiPrevented = true;
	  };
	
	  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		if (params.field === 'ring_gauge' && params.reason === GridRowEditStopReasons.enterKeyDown) {
			handleSaveClick(params.id)();
			let max_id = vitolas.reduce<number>((aggregator, current) => {
				if (parseInt(current.id!)) {
					return Math.max(aggregator, parseInt(current.id!));
				}
				return aggregator;
			}, 0);
			max_id++;
			setVitolas((oldRows) => [...oldRows, {id: max_id.toString(), name: '', shape: 'Parejo', isNew: true }]);
			setRowModesModel((oldModel) => ({
				...oldModel,
				[max_id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
			}));
		}
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			handleSaveClick(params.id)();
		}
		if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
			handleCancelClick(params.id)();
		}

		event.defaultMuiPrevented = true;
	  };
	
	  const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' } });
	  };
	
	  const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	  };
	
	  const handleDeleteClick = (id: GridRowId) => () => {
		setVitolas(vitolas.filter((row) => row.id !== id));
	  };
	
	  const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
		  ...rowModesModel,
		  [id]: { mode: GridRowModes.View, ignoreModifications: true },
		});
	
		const editedRow = vitolas.find((row) => row.id === id);
		if (editedRow!.isNew) {
		  setVitolas(vitolas.filter((row) => row.id !== id));
		}
	  };
	
	  const processRowUpdate = (newRow: GridRowModel) => {
		const rows = vitolas.map((row) => (row.id === newRow.id ? newRow as Vitola : row));
		setVitolas(rows);
		return newRow as Vitola;
	  };

	const VitolaColumns = [
		{field: 'name', headerName: 'Name', width: 160, editable: true },
		{field: 'shape', headerName: 'Shape', editable: true },
		{field: 'length', headerName: 'Length', editable: true, type: 'number' },
		{field: 'ring_gauge', headerName: 'Ring Gauge', editable: true, type: 'number' },
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: ( {id}: {id: any} ) => {
			const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
	
			if (isInEditMode) {
				return [
				<GridActionsCellItem
					icon={<SaveIcon />}
					label="Save"
					onClick={handleSaveClick(id)}
				/>,
				<GridActionsCellItem
					icon={<CancelIcon />}
					label="Cancel"
					className="textPrimary"
					onClick={handleCancelClick(id)}
					color="inherit"
				/>,
				];
			}
	
			return [
				<GridActionsCellItem
				icon={<EditIcon />}
				label="Edit"
				className="textPrimary"
				onClick={handleEditClick(id)}
				color="inherit"
				/>,
				<GridActionsCellItem
				icon={<DeleteIcon />}
				label="Delete"
				onClick={handleDeleteClick(id)}
				color="inherit"
				/>,
			];
			},
		},
	];	

	return (
		<React.Fragment>
			<DataGrid 
				rows={vitolas}	
				columns={VitolaColumns}
				editMode="row"
				rowModesModel={rowModesModel}
				onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
				pageSize={20}
				rowsPerPageOptions={[20]}
				getRowId={(row) => row.id!}
				components={{
					Toolbar: EditToolbar,
				}}
				componentsProps={{
					toolbar: { setRows: setVitolas, setRowModesModel, geminiVitolas, setGeminiModalOpen },
				}}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				experimentalFeatures={{ newEditingApi: true }}
			/>
			<Dialog 
				open={geminiModalOpen}
				onClose={() => setGeminiModalOpen(false)}
			>
				<DialogTitle>Import from Gemini</DialogTitle>
				<Box sx={{ p: 2 }}>
					<FormGroup>
						<FormControlLabel 
							control={<Checkbox />} 
							label="Select all" 
							onChange={(e, c) => c ? setSelectedGeminiVitolas(selectedGeminiVitolas.concat(geminiVitolas!)) : setSelectedGeminiVitolas([]) } 
							checked={selectedGeminiVitolas.length === geminiVitolas?.length}
						/>
						<Divider />
						{geminiVitolas?.map((vitola) => (
							<FormControlLabel 
								key={vitola.id}
								control={<Checkbox />} 
								label={`${vitola.name} - ${vitola.shape} - ${vitola.length}x${vitola.ring_gauge}`} 
								onChange={(e, c) => c ? setSelectedGeminiVitolas([...selectedGeminiVitolas, vitola]) : setSelectedGeminiVitolas(selectedGeminiVitolas.filter((v) => v.id !== vitola.id))} 
								checked={selectedGeminiVitolas.includes(vitola)}
							/>
						))}
					</FormGroup>
				</Box>
				<DialogActions>
					<Button onClick={() => setGeminiModalOpen(false)}>Cancel</Button>
					<Button onClick={() => {
						setVitolas((oldVitolas) => [...oldVitolas, ...selectedGeminiVitolas])
						setGeminiModalOpen(false)
					}}>Save</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}