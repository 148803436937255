import { 
	Alert,
	AlertColor,
	Autocomplete,
	Box,
	BoxProps,
	Button,
	CircularProgress,
	Container,
	Dialog,
	DialogContent,
	Drawer,
	IconButton,
	LinearProgress,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	Unstable_Grid2 as Grid,
} from "@mui/material"
import {
	ImageNotSupported,
	AutoAwesome,
	Launch as LaunchIcon,
} from "@mui/icons-material"
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import React, { useState, useRef, useEffect, SetStateAction } from 'react'
import { CreateCigarLine, GetCigarLineImage, UpdateCigarLine, CreateLineFromTempLine, fetcher } from "../apiservice"
import { Vitola, CigarLine } from './Models'
import { VitolaGrid } from './VitolaGrid'
import { UploadImageDialog } from './UploadImageDialog'
import { GoogleGenerativeAI } from "@google/generative-ai"
import useSWR from 'swr'


enum Mode {
	NEW,
	EDIT,
	USER_ENTERED,
}

function Item(props: BoxProps) {
	const { sx, ...other } = props;
	return (
	  <Box
		sx={{
		  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
		  color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
		  p: 1,
		  m: 1,
		  borderRadius: 2,
		  fontSize: '0.875rem',
		  fontWeight: '700',
		  ...sx,
		}}
		{...other}
	  />
	);
  }

function useDebounce(value: any, delay: number) {
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setTempValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return tempValue;
}

export function CigarDetail() {
	// Set up Gemini
	const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY!);
	const model = genAI.getGenerativeModel({ model: 'gemini-1.5-pro' });

	const [searchParams] = useSearchParams();
	let cigar = useLoaderData() as CigarLine;

	// If the loader worked, EDIT mode. If line_id is in the query params, USER_ENTERED. Otherwise NEW
	const mode = cigar ? Mode.EDIT : searchParams.get("line_id") ? Mode.USER_ENTERED : Mode.NEW;
	const defaultCigar: CigarLine = {
		id: searchParams.get("line_id") ?? undefined,
		brand_id: '',
		brand: {id: '', name: searchParams.get("brand_name") ?? '', manufacturer: null, manufacturer_id: ''},
		name: searchParams.get("line_name") ?? '',
		description: '',
		wrapper_origin: '',
		binder_origin: '',
		filler_origin: '',
		thumbnail: '',
		vitolas: [],
	};

	// Initialize form state
	const [{currentState, isDirty}, setEditState] = useState<{currentState: CigarLine, isDirty: boolean}>({currentState: cigar ?? defaultCigar, isDirty: false});
	const debouncedName = useDebounce(currentState.name, 500);

	// Modal controls
	const [imageDetailOpen, setImageDetailOpen] = useState<boolean>(false);
	const [imageUploadOpen, setImageUploadOpen] = useState<boolean>(false);
	const [{showToast, severity, message}, setSnackbar] = useState<{showToast: boolean, severity: AlertColor, message: string}>({showToast: false, severity: "success", message: ""});
	const nameEdit = useRef(null);
	const navigate = useNavigate();

	const { data: brands, error: brandsError, isLoading: brandsLoading } = useSWR('/v1/cigar/brand', fetcher);
	// Display an error if the brands fail to load
	useEffect(() => {
		if (brandsError) {
			setSnackbar({showToast: true, severity: "error", message: `There was an issue loading the brands: ${brandsError}`});
		}
	}, [brandsError]);

	// If the brand name is in the query params, set the brand_id
	useEffect(() => {
		if (brands && !currentState.brand_id) {
			const brand = brands.find((b: any) => b.name === searchParams.get("brand_name"));
			if (brand) {
				setEditState({currentState: {...currentState, brand_id: brand.id, brand: brand}, isDirty: true});
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brands])

	const geminiFetcher = async (combined_name: string) => {
		if (combined_name.trim() === "") {
			return null;
		}

		const prompt = `Summarize the ${combined_name} cigar line with the following
			information: The correct brand name, correct cigar line name, a 2-3 sentence description of the cigar, the type of tobacco
			used in the wrapper, the type of tobacco used in the binder, the type of tobacco used in the filler, and 
			a table of the available vitolas including the vitola name, shape, length, and ring gauge. If the manufacturer
			has a website, include the URL for the website as well. Return only 
			a JSON object containing the fields brand_name, name, description, wrapper_origin, binder_origin, filler_origin, manufacturer_url and vitolas. Each
			row in the vitolas array should contain the fields name, shape, length, and ring_gauge. Valid values for the shape
			are "Parejo", "Figurado", "Box-Pressed", "Culebra", "Torpedo", "Perfecto", "Belicoso", "Diadema", "Cigarillo", and "Tubo". Prioritize
			data found on the manufacturer's website. Respectable blog websites like Cigar Aficianado and Halfwheel may be used as well. If the
			cigar line does not exist in real life, return an empty object.`;
		
		const result = await model.generateContent(prompt)
		const jsonContent = result.response.text().replace(/^[^{]*{/, "{").replace(/}[^}]*$/, "}");
		if (jsonContent === "{}") {
			throw new Error("No data found for this cigar line");
		}

		const line: CigarLine = await JSON.parse(jsonContent)
		return line;
	}
	const {data: geminiData, error: geminiError, isLoading: geminiLoading} = useSWR(`${currentState.brand?.name} ${debouncedName}`, geminiFetcher);
	useEffect(() => {
		if (!geminiLoading && geminiError) {
			setSnackbar({showToast: true, severity: "error", message: `There was an issue loading the Gemini data: ${geminiError}`});
		}
	}, [geminiLoading, geminiError])

	return (
		<Container>
			<Grid container spacing={2}>
				<Grid sm={12} md={4}>
					<Item>
						<Paper sx={{ p: 1 }} 
							>
							{ currentState.thumbnail ? 
								<img 
									src={ `data:image/png;base64,${currentState.thumbnail}` } 
									alt="" 
									style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain' }} 
									onClick={ () => setImageDetailOpen(true) } 
								/> :
								<ImageNotSupported sx={{ height: 180, width: 180 }} />
							}
							{
								imageDetailOpen ?
								<ImageDetail 
									cigarId={ currentState.id! } 
									closeCallback={ () => setImageDetailOpen(false) } 
								/> :
								null
							}
							{ currentState.id && !isDirty ?
								<Button onClick={ () => setImageUploadOpen(true) }>
									<Typography>Replace Image</Typography>
								</Button> :
								<Typography>Save First</Typography>
							}
							<UploadImageDialog 
								cigarId={ currentState.id! }
								open={ imageUploadOpen }
								closeCallback={ () => setImageUploadOpen(false) }
								successCallback={ (data) => {
									setSnackbar({showToast: true, severity: "success", message: "Image uploaded successfully!"});
									setEditState({currentState: data, isDirty: false});
								}}
								errorCallback={ () => setSnackbar({showToast: true, severity: "error", message: "There was an issue uploading your image"}) }
							/>
							<Snackbar open={showToast} autoHideDuration={1500} onClose={() => setSnackbar({showToast: false, severity: "success", message: ""})}>
								<Alert severity={ severity }>
									{ message } 
								</Alert>
							</Snackbar>
						</Paper>
					</Item>
				</Grid>
				<Grid sm={12} md>
					<Item sx={{ textAlign: 'left' }}>
						<Autocomplete 
							disablePortal
							id="brand-select"
							value={currentState.brand_id ? currentState.brand : null}
							options={brands ?? []}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							loading={brandsLoading}
							loadingText="Loading brands..."
							renderInput={(params) => <TextField {...params} label="Brand" InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{brandsLoading ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
							/>}
							onChange={(e, v) => {
								setEditState({currentState: {
									...currentState, 
									brand_id: v?.id ?? '', 
									brand: v
								}, isDirty: true});
								navigator.clipboard.writeText(`${v?.name} ${currentState.name}`);	
							}}
							sx={{ mb: 2 }}
						/> 
						<TextField 
							id="name-edit" 
							ref={nameEdit}
							label="Name" 
							variant="outlined" 
							InputProps={{
								style: { fontSize: '3.5rem'}, 
								endAdornment: (
									<React.Fragment>
										{ geminiData ?
										<Tooltip title={ geminiData.name }><IconButton onClick={() => setEditState({currentState: {...currentState, name: geminiData.name}, isDirty: currentState.name !== geminiData.name })}><AutoAwesome /></IconButton></Tooltip> : null }
									</React.Fragment>
								),
							}}
							value={currentState.name} 
							onKeyDown={(e) => {
								if (e.key === "Escape") {
									setEditState({currentState: {...currentState, name: cigar.name}, isDirty: true});
								}
							}}
							onChange={(e) => {
								setEditState({currentState: {...currentState, name:e.target.value}, isDirty: true});
								navigator.clipboard.writeText(`${currentState.brand?.name} ${currentState.name}`);
							}}
							sx={{ width: '100%', mb: 2 }}
						/>
						{ geminiLoading ? <LinearProgress color="secondary" /> : null }
						{ !geminiLoading && geminiData?.manufacturer_url ? <Button href={ geminiData.manufacturer_url } target="_blank" rel="noreferrer" endIcon={<LaunchIcon />}>Manufacturer Website</Button> : null }
					</Item>	
				</Grid>
				<Grid xs={12}>
					<Item>
						<TextField 
							id="description-edit" 
							ref={nameEdit}
							label="Description" 
							variant="outlined" 
							value={currentState.description} 
							fullWidth={true}
							multiline={true}
							onKeyDown={(e) => {
								if (e.key === "Escape") {
									setEditState({currentState: {...currentState, description: cigar.description}, isDirty: true});
								}
							}}
							onChange={(e) => {
								setEditState({currentState: {...currentState, description:e.target.value}, isDirty: true});
							}}
							InputProps={{
								endAdornment: (
									<React.Fragment>
										{ geminiData ?
										<Tooltip title={ geminiData?.description } onClick={
												() => setEditState({currentState: {...currentState, description: geminiData.description}, isDirty: true})
											}><IconButton><AutoAwesome /></IconButton></Tooltip> : null }
									</React.Fragment>
								),
							}}
						/>
					</Item>
				</Grid>
				<Grid sm={12} md={4}>
					<TextField
						id="wrapper-edit"
						label="Wrapper Origin"
						value={currentState.wrapper_origin}
						onChange={(e) => {
							setEditState({currentState: {...currentState, wrapper_origin:e.target.value}, isDirty: true});
						}}	
						InputLabelProps={{shrink: true}}
						InputProps={{
							endAdornment: (
								<React.Fragment>
									{ geminiData ?
									<Tooltip title={ geminiData?.wrapper_origin } onClick={
											() => setEditState({currentState: {...currentState, wrapper_origin: geminiData.wrapper_origin}, isDirty: true})
										}><IconButton><AutoAwesome /></IconButton></Tooltip> : null }
								</React.Fragment>
							),
						}}
					/>
				</Grid>
				<Grid sm={12} md={4}>
					<TextField
						id="binder-edit"
						label="Binder Origin"
						value={currentState.binder_origin}
						onChange={(e) => {
							setEditState({currentState: {...currentState, binder_origin:e.target.value}, isDirty: true});
						}}	
						InputLabelProps={{shrink: true}}
						InputProps={{
							endAdornment: (
								<React.Fragment>
									{ geminiData ?
									<Tooltip title={ geminiData?.binder_origin } onClick={
											() => setEditState({currentState: {...currentState, binder_origin: geminiData.binder_origin}, isDirty: true})
										}><IconButton><AutoAwesome /></IconButton></Tooltip> : null }
								</React.Fragment>
							),
						}}
					/>
				</Grid>
				<Grid sm={12} md={4}>
					<TextField
						id="filler-edit"
						label="Filler Origin"
						value={currentState.filler_origin}
						onChange={(e) => {
							setEditState({currentState: {...currentState, filler_origin:e.target.value}, isDirty: true});
						}}	
						InputLabelProps={{shrink: true}}
						InputProps={{
							endAdornment: (
								<React.Fragment>
									{ geminiData ?
									<Tooltip title={ geminiData?.filler_origin } onClick={
											() => setEditState({currentState: {...currentState, filler_origin: geminiData.filler_origin}, isDirty: true})
										}><IconButton><AutoAwesome /></IconButton></Tooltip> : null }
								</React.Fragment>
							),
						}}
					/>
				</Grid>
				<Grid xs={12} md={10} mb={"60px"}>
					<Item sx={{ height: 1000 }}>
						<VitolaGrid 
							vitolas={currentState.vitolas} 
							setVitolas={(vitolas: SetStateAction<Vitola[]>) => {
								setEditState((prev) => ({currentState: {...prev.currentState, vitolas: typeof vitolas === "function" ? vitolas(prev.currentState.vitolas) : vitolas}, isDirty: true}))
							}}
							geminiVitolas={geminiData?.vitolas?.map((v: any) => ({...v, id: Math.random().toString(36).substring(7), isNew: true})) ?? []}
						/>
					</Item>
				</Grid>
				<Grid md={2}>
					<Item>
						<TableContainer component={Paper} sx={{ border: '1px solid black'}}>
							<Table size="small" style={{ width: 'auto', tableLayout: 'fixed' }}>
								<TableBody>
									{[
										["1/16", 0.0625], 
										["1/8", 0.125], 
										["3/16", 0.1875], 
										["1/4", 0.25], 
										["5/16", 0.3125], 
										["3/8", 0.375], 
										["7/16", 0.4375], 
										["1/2", 0.5], 
										["9/16", 0.5625], 
										["5/8", 0.625], 
										["11/16", 0.6875], 
										["3/4", 0.75], 
										["13/16", 0.8125], 
										["7/8", 0.875], 
										["15/16", 0.9375]
									].map(([fraction, decimal], index) => (
										<TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'transparent' }}>
											<TableCell style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>{fraction}</TableCell>
											<TableCell style={{ fontSize: '0.75rem' }}>{decimal}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>	
					</Item>
				</Grid>
			</Grid>
			<Drawer open={isDirty} anchor={'bottom'} variant={'persistent'} >
				<Button onClick={() => setEditState({currentState: cigar ?? defaultCigar, isDirty: false})} >
					Cancel
				</Button>
				<Button onClick={async () => {
					if (mode !== Mode.EDIT) {
						let newLine: CigarLine;
						currentState.vitolas.forEach((v) => {
							delete v.id;
						});

						// new line from temp line
						if (mode === Mode.USER_ENTERED) {
							currentState.id = searchParams.get("line_id")!;
							newLine = await CreateLineFromTempLine(currentState) as CigarLine;
						} else {
							// new line totally from scratch
							newLine = await CreateCigarLine(currentState) as CigarLine;
							// Clear edit state
						} 
						setEditState({currentState: newLine, isDirty: false});
						return navigate(`/cigars/${newLine.id}`);
					}

					// existing line
					const updated = await UpdateCigarLine(currentState);
					cigar = updated
					setEditState({currentState: updated, isDirty: false})
				}}>Save</Button>
			</Drawer>
		</Container>
	)
}

function ImageDetail({cigarId, closeCallback}:
	{cigarId: string, closeCallback: () => void}) {
		const [cigarImage, setCigarImage] = useState<{image: string} | null>(null)
		const [open, setOpen] = useState<boolean>(false);
		useEffect(() => {
			(async () => {
				const image = await GetCigarLineImage(cigarId);
				setCigarImage(image);
				setOpen(true);
			})()
		}, [cigarId])
		return (
			<Dialog open={open} onClose={closeCallback} maxWidth={"lg"}>
				<DialogContent>
					{ cigarImage == null ?
						<CircularProgress /> :
						<img src={`data:image/png;base64,${cigarImage.image}`} alt="" />
					}
				</DialogContent>
			</Dialog>
		)
}