import { 
	Button, 
	CircularProgress, 
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography 
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { fetcher, DeleteTempLine } from "../apiservice";
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { TempLine } from "./Models";
import useSWR from "swr";
 
let navigate: NavigateFunction;

function ReviewLines() {
	navigate = useNavigate();
	const [deleteItem, setDeleteItem] = useState<{id: string, name: string} | null>(null);
	const [pendingRequest, setPendingRequest] = useState<boolean>(false);
	const columns = [
		{ field: 'brand_name', headerName: 'Brand', width: 240},
		{ field: 'name', headerName: 'Line' , width: 240 },
		{ field: 'actions', type: 'actions', getActions: (params: any) => [
			<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => navigate(`/review_lines/${params.row.id}`)} />,
			<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => setDeleteItem({id: params.row.id, name: params.row.name})} />,
		]}
];

	const { data, error, isLoading, mutate } = useSWR('/v1/cigar/temporary', fetcher);

	if (isLoading) return <CircularProgress />;
	if (error) return <Typography paragraph>{ error.message }</Typography>;
	return (
		<div style={{ height: 800, width: '100%' }}>
			<DataGrid 
				rows={data!}	
				columns={columns}
				pageSize={25}
				getRowId={(row) => row.id}
				components={{
					 Toolbar: ReviewLinesToolbar,
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: 'brand_name', sort: 'asc' }],
					}
				}}
			/>
			<Dialog 
				open={deleteItem != null} 
				onClose={() => {
					setDeleteItem(null);
				}}>
				<DialogTitle>
					{`Delete unverified line "${deleteItem?.name}"?`}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This action can not be undone.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteItem(null)}>Cancel</Button>
					<Button onClick={async () => {
						setPendingRequest(true);
						await DeleteTempLine(deleteItem!.id);
						setDeleteItem(null);
						mutate({ data: data.filter((e: TempLine) => e.id !== deleteItem!.id) })
						setPendingRequest(false);
					}} disabled={pendingRequest}>Delete</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function ReviewLinesToolbar() {
	return (
		<div>
			<GridToolbarContainer>
				<GridToolbarFilterButton />
			</GridToolbarContainer>
		</div>
	)
}

export { ReviewLines };