import { 
	Autocomplete, 
	Button, 
	CircularProgress,
	Drawer, 
	FormControl, 
	TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { CreateBrand, UpdateBrand, fetcher } from "../apiservice";
import { Brand } from "./Models";
import useSWR from 'swr';
import React from "react";

export function BrandDetail() {
	const defaultBrand = {
		manufacturer: '',
		name: '',
	};
	let brand = useLoaderData() as Brand;
	const [{currentState, isDirty}, setEditState] = useState<{currentState: Brand, isDirty: boolean}>({currentState: brand ?? defaultBrand, isDirty: false});
	const nameEdit = useRef(null);
	const navigate = useNavigate();
	const { data: allManufacturers, error: manufacturerError, isLoading: manufacturersLoading } = useSWR('/v1/cigar/manufacturer', fetcher);
	useEffect(() => {
		if (!manufacturersLoading && manufacturerError) {
			console.error(manufacturerError)
		}
	}, [manufacturersLoading, manufacturerError])

	return (
		<Container>
			<FormControl fullWidth>
				<Autocomplete 
					disablePortal
					id="manufacturer-select"
					value={currentState.manufacturer_id ? currentState.manufacturer : null}
					options={allManufacturers ?? []}
					getOptionLabel={(option) => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					loading={manufacturersLoading}
					loadingText="Loading manufacturers..."
					renderInput={(params) => <TextField {...params} label="Manufacturer" InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{manufacturersLoading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
					/>}
					onChange={(e, v) => {
						setEditState({currentState: {
							...currentState, 
							manufacturer_id: v?.id ?? '', 
							manufacturer: v
						}, isDirty: true});
						navigator.clipboard.writeText(`${v?.name} ${currentState.name}`);	
					}}
					sx={{ mb: 2 }}
				/> 
			</FormControl>
			<TextField 
				id="name-edit" 
				ref={nameEdit}
				label="Name" 
				variant="outlined" 
				inputProps={{style: {fontSize: "5.5rem"}}} // font size of input text
				value={currentState.name} 
				onKeyDown={(e) => {
					if (e.key === "Escape") {
						setEditState({currentState: {...currentState, name: brand.name}, isDirty: true});
					}
				}}
				onChange={(e) => {
					setEditState({currentState: {...currentState, name:e.target.value}, isDirty: true});
				}}
			/> 
			<Drawer open={isDirty} anchor={'bottom'} variant={'persistent'} >
				<Button onClick={() => setEditState({currentState: brand ?? defaultBrand, isDirty: false})} >
					Cancel
				</Button>
				<Button onClick={async () => {
					if (!currentState.id) {
						const newBrand = await CreateBrand(currentState) as Brand;
						// Clear edit state
						setEditState({currentState: newBrand, isDirty: false});
						return navigate(`/brands/${newBrand.id}`);
					}
					const updated = await UpdateBrand(currentState);
					brand = updated
					setEditState({currentState: updated, isDirty: false})
				}}>Save</Button>
			</Drawer>
		</Container>
	)
}