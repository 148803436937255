import './App.css';
import { useEffect, useState } from 'react';
import {
	Alert,
	AppBar,
	Box,
	CircularProgress,
	Container,
	CssBaseline,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	SmokingRooms,
	BrandingWatermark,
	Factory,
	Menu as MenuIcon,
	Add,
} from '@mui/icons-material';
import { Link, Outlet } from 'react-router-dom';


const drawerWidth = 240;
const kratosPublicUrl = process.env.REACT_APP_KRATOS_PUBLIC_URL;

function App() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [{loggedIn, authorized}, setLoggedIn] = useState<{loggedIn: boolean, authorized: boolean}>({loggedIn: false, authorized: false});
  const [drawerOpen, setDrawerOpen] = useState<boolean>(!smallScreen);

  useEffect(() => {
    if (!loggedIn) {
      	(async () => {
			const base_url = process.env.REACT_APP_API_URL;
			let fetch_options: RequestInit = {credentials: 'include'};
			const cookie = process.env.REACT_APP_KRATOS_COOKIE;
			if (cookie) {
				fetch_options.headers = {
					'Authorization': `Bearer ${cookie}`,
				}
			}
			let response = await fetch(`${base_url}/auth/session`, fetch_options);
			
			if (response.status === 401) {
				// We need to auth through Kratos first, we will be returned here after authenticating
				window.location.href = `${kratosPublicUrl}/self-service/login/browser?return_to=${process.env.PUBLIC_URL}`;
			} 

			if (response.ok) {
				const profile = await response.json();
				setLoggedIn({loggedIn: true, authorized: profile.roles?.includes("admin")});
			} else {
				// Something else is wrong
				setLoggedIn({loggedIn: true, authorized: false});
			}
		})();
	}
}, [loggedIn]);

  	if (!loggedIn) return <Container><CircularProgress size={100} thickness={5} /></Container>;
	if (!authorized) return <Alert severity='error'>You are not authorized to access this site</Alert>

	return (<Box className="App" sx={{ display: 'flex' }}>
		<CssBaseline />
		<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "black" }}>
		<Container maxWidth="xl">
			<Toolbar disableGutters>
				<IconButton sx={{ display: { sm: 'block', md: 'none' }}} onClick={() => setDrawerOpen(!drawerOpen)}>
					<MenuIcon sx={{ color: 'white' }} />
				</IconButton>	
				<Link to="/" style={{ display: 'flex', flexWrap: 'nowrap' }}>
					<img src={`${process.env.PUBLIC_URL}/unwrapped-circle-TM.svg`} alt="Unwrappd" width="40" height="40" /> 
					<Typography variant="h6" noWrap sx={{ textDecoration: 'none', color: 'white' }}> ADMIN </Typography>
				</Link>
			</Toolbar>
		</Container>
		</AppBar>
		<Drawer
		variant={ smallScreen ? "temporary" : "permanent" }
		sx={{
			width: drawerWidth,
			flexShrink: 0,
			[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
		}}
		open={ drawerOpen }
		>
		<Toolbar />
		<Box sx={{ overflow: 'auto' }}>
			<List>
			<ListItem key="cigars" secondaryAction={
				<Link to="/cigars/new">
					<IconButton edge="end" aria-label="add"><Add /></IconButton>
				</Link>
			}>
				<Link to="/cigars">
				<ListItemButton>
					<ListItemIcon><SmokingRooms /></ListItemIcon>
					<ListItemText primary="Cigars" />
				</ListItemButton>
				</Link>
			</ListItem>
			<ListItem key="brands" secondaryAction={
				<Link to="/brands/new">
					<IconButton edge="end" aria-label="add"><Add /></IconButton>
				</Link>
			}>
				<Link to="/brands">
					<ListItemButton>
						<ListItemIcon><BrandingWatermark /></ListItemIcon>
						<ListItemText primary="Brands" />
					</ListItemButton>
				</Link>
			</ListItem>
			<ListItem key="manufacturers" secondaryAction={
				<Link to="/manufacturers/new">
					<IconButton edge="end" aria-label="add"><Add /></IconButton>
				</Link>
			}>
				<Link to="/manufacturers">
					<ListItemButton>
						<ListItemIcon><Factory /></ListItemIcon>
						<ListItemText primary="Manufacturers" />
					</ListItemButton>
				</Link>
			</ListItem>
			<ListItem key="review_lines">
				<Link to="/review_lines">
					<ListItemButton>
						<ListItemIcon><Factory /></ListItemIcon>
						<ListItemText primary="ReviewLines" />
					</ListItemButton>
				</Link>
			</ListItem>
			</List>
		</Box>
		</Drawer>
		<Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
			<Outlet />
		</Box>
	</Box>);
}

export default App;
