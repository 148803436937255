import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App'
import { Cigars } from './cigars/Cigars';
import { CigarDetail } from './cigars/CigarDetail';
import { Dashboard } from './dashboard/Dashboard';
import { GetBrandDetail, GetCigarDetail, GetManufacturerDetail } from './apiservice';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Brands } from './cigars/Brands';
import { BrandDetail } from './cigars/BrandDetail';
import { Manufacturers } from './cigars/Manufacturers';
import { ManufacturerDetail } from './cigars/ManufacturerDetail';
import { ReviewLines } from './cigars/ReviewLines';
import { ReviewDetail } from './cigars/ReviewDetail';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "cigars",
        element: <Cigars />,
      },
      {
        path: "cigars/new",
        element: <CigarDetail />,
      },
      {
        path: "cigars/:lineId",
        element: <CigarDetail />,
        loader: async ({params}) => GetCigarDetail(params.lineId!),
      },
      {
        path: "review_lines",
        element: <ReviewLines />,
      },
      {
        path: "review_lines/:lineId",
        element: <ReviewDetail />,
        loader: async ({params}) => GetCigarDetail(params.lineId!),
      },
      {
        path: "brands",
        element: <Brands />,
      },
      {
        path: "brands/new",
        element: <BrandDetail />,
      },
      {
        path: "brands/:brandId",
        element: <BrandDetail />,
        loader: async({params}) => GetBrandDetail(params.brandId!),
      },
      {
        path: "manufacturers",
        element: <Manufacturers />,
      },
      {
        path: "manufacturers/new",
        element: <ManufacturerDetail />,
      },
      {
        path: "manufacturers/:mfgId",
        element: <ManufacturerDetail />,
        loader: async({params}) => GetManufacturerDetail(params.mfgId!),
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
